<template lang="pug">
.filter-uniq-type-container.finance-form-mobile(
  v-show="isFormsVisible"
)
  .container-user
    .finance-form-mobile-row.filter-uniq-type.text-sm-right
      .filter-title-uniq {{ $t("financeFormSelect.text") }} &#8811;
      div
        .filter-item-uniq
          v-drop-down(
            option-label="name"
            option-value="id"
            label=""
            v-model="financeFormId"
            :options="financeFormsFilteredSweden"
            :disabled="false"
            :multiple="false"
            @click="changeFinanceForm($event)"
          )
</template>

<script>
import {mapState, mapActions, mapGetters, } from 'vuex'
import { isMobileWidth, camelize, } from '@/helpers/helpers'

export default {
  name: 'FinanceFormsMobile',
  components: {
    VDropDown: () => import("@/components/helps/DropDown"),
  },
  data: () => ({
    isFormsVisible: true,
  }),
  computed: {
    ...mapState('global', ['generalFormsCollectionName', ]),
    ...mapState('filter', {
      financeForms: state => state.allFinanceForms,
    }),
    ...mapGetters('filter', ['getFilteredFinanceForms']),
    financeFormsFiltered() {
      const filteredFinanceForms = this.getFilteredFinanceForms(this.generalFormsCollectionName);

      return !filteredFinanceForms.length ? [] : filteredFinanceForms;
    },
    financeFormsFilteredSweden() {
      var newObj = JSON.parse(JSON.stringify(this.financeFormsFiltered));

      return newObj.map(item => {
        item['name'] = this.$t(`financeForms.${camelize(item.name)}`);

        return item;
      });
    },
    financeFormId: {
      get() {
        return this.$store.state.filter.finance_form_id;
      },
      set(value) {
        this.$store.commit("filter/setFinanceFormId", value);
      }
    }
  },
  created() {
    const _this = this;
    _this.setFormsVisibily()

    window.addEventListener('resize', function() {
      _this.setFormsVisibily();
    })
  },
  methods: {
    ...mapActions('filter', [ 'updateFinanceFormName', ]),
    ...mapActions('reseller', [ 'updateBuilder', ]),
    async changeFinanceForm(e) {
      const financeFormName = this.financeFormsFiltered.find(item => item.id == e);

      await this.updateFinanceFormName(financeFormName.name);

      this.$emit('change');

      await this.updateBuilder();
      await this.$router.replace({ query: { ...this.$route.query, finance_form: financeFormName, } });

    },
    setFormsVisibily() {
      const visibility = isMobileWidth() && this.financeFormsFiltered.length > 1;

      this.$set(this.$data, 'isFormsVisible', visibility);
    }
  },
  watch: {
    financeForms: {
      handler: function(n) {
        if (n.length) {
          this.$set(this.$data, 'isFormsVisible', isMobileWidth() && this.financeFormsFiltered.length > 1);
        }
      },
      deep: true,
    },
  }
}
</script>

<style lang="sass">
  .finance-form-mobile
    background-color: #bbbabb
    margin: 2px 0

    &-row
      display: flex
      align-items: center
      justify-content: center

      > *
        width: 50%


</style>
